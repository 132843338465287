import React, { useState } from 'react';
import { Link } from "react-router-dom";

export default function ModalPrincipal() {
    const [isOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>

      {isOpen && (
        <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed z-50 top-0 justify-center items-center w-full h-full overflow-x-hidden"
        >

            <div className="relative flex items-center justify-center p-4 w-full h-full mx-auto" 
            style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo con opacidad
          }}>
                <div className="w-full h-full relative rounded-lg grip content-end" style={{
                    backgroundImage: 'url(\'../images/ahorro_afp.png\')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    }}>
                <button
                    type="button"
                    className="absolute top-3 right-2.5 text-secondary-400 bg-transparent hover:bg-secondary-400 hover:text-primary-50 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                    onClick={closeModal}
                >
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                    <div className="p-4 md:p-5 text-center grip justify-items-center content-end">
                        <Link
                        to={"/cuenta-estrella"}
                        preventScrollReset={true}>
                        <button className='p-2 bg-primary-50 text-primary-800 font-bold rounded-lg shadow-lg'>
                            MÁS INFORMACIÓN</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        )}
        
        </>
    )
}
